import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './router';
import './index.css'
import 'swiper/css';
import '@nutui/nutui-react/dist/style.css'
import {
  ConfigProvider
} from "@nutui/nutui-react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider theme={{
    nutuiBrandColor: 'rgb(245, 119, 102)',
    nutuiBrandColorStart: 'rgb(245, 119, 102)',
    nutuiBrandColorEnd: 'rgb(245, 119, 102)',
  }}>
    <App />
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
