import KuanshiIcon from '../../../../static/img/kuanshi.png'
import SucaiIcon from '../../../../static/img/sucai.png'
import TuyaIcon from '../../../../static/img/tuya.png'
import WenziIcon from '../../../../static/img/wenzi.png'
import PluginsContext from '../context'
import TContext from '../../context'
import { useContext, LegacyRef, useRef, useImperativeHandle } from 'react'

export const Height = 56

export const foot: React.CSSProperties = {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: Height,
    background: '#ffffff',
    boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.10)',
    zIndex: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    left: 0,
    //paddingBottom: 'constant(safe-area-inset-bottom)',//兼容 IOS<11.2
    paddingBottom: 'env(safe-area-inset-bottom)'//兼容 IOS>11.2
}

export const foot_item = {
    margin: '0 auto'
}

export const foot_txt: React.CSSProperties = {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(51, 51, 51, 1)',
    textAlign: "center"
}

export const iconStyle = {
    width: 30,
    height: 30
}

interface option {
    icon: string
    text: string
    key: MenuType
    hidden: boolean
    // handle: React.MouseEventHandler
}

export interface Props {
    onClick?: any
    cutpartList?: cutpartListItem[]
    swiperClient?: any
    editText?: boolean
    action?: any
}

interface cutpartListItem {
    code: string
    img: string
    show?: boolean
    camera?: any
}

export enum MenuType {
    KUANSHI = 1,
    SUCAI = 2,
    TUYA = 3,
    WENZI = 4
}

export const options: option[] = [
    {
        icon: KuanshiIcon,
        text: "款式",
        key: MenuType.KUANSHI,
        hidden: false
    },
    {
        icon: SucaiIcon,
        text: "素材",
        key: MenuType.SUCAI,
        hidden: false
    },
    {
        icon: TuyaIcon,
        text: "涂鸦",
        key: MenuType.TUYA,
        hidden: false
    },
    {
        icon: WenziIcon,
        text: "文字",
        key: MenuType.WENZI,
        hidden: false
    }
]

export interface Action {
    getHeight?: () => number;
}


const Index = (props: Props) => {
    const pluginContext = useContext(PluginsContext)
    const tc = useContext(TContext)

    const contentRef: LegacyRef<HTMLDivElement> = useRef(null)

    const getHeight = () => {
        return contentRef.current?.offsetHeight as number
    }

    useImperativeHandle(props.action, (): Action => ({
        getHeight
    }));

    return (
        <>
            <div ref={contentRef} style={{ display: 'flex', ...foot }}>
                {
                    options?.map(v => (
                        !v.hidden && <div style={foot_item} key={v.key} onClick={(e) => {
                            e.preventDefault()
                            pluginContext?.showPlugin!(v.key as MenuType)
                            //点击菜单按钮失去焦点
                            tc.curCanvasClient?.discardActiveObject()
                        }}>
                            <img style={iconStyle} src={v.icon} alt="" />
                            <div style={foot_txt}>{v.text}</div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Index