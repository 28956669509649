import { useEffect, useState } from 'react';
import http from '../../lib/http'
import * as apis from '../../lib/apis'
import { Button, Toast } from '@nutui/nutui-react';

const Index = () => {
    const [d, setD] = useState<any>()
    const [fashionId, setFashionId] = useState()
    useEffect(() => {
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })

        http.get(apis.INDEX_DATA).then(async (data: any) => {
            if (data.config) {
                data.config = JSON.parse(data.config)
            }
            setD(data)

            const patterns: any = await http.post(apis.PRODUCT_LIST, {
                page: 1,
                page_size: 1,
                status: 1
            })
            if (patterns?.list && patterns?.list?.length !== 0) {
                setFashionId(patterns?.list[0].id)
            }

            Toast.hide()
        })

    }, [])

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url(${process.env.REACT_APP_OSS_URL + d?.config?.bg_url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "90%",
            backgroundPosition: "bottom center",
            backgroundColor: d?.config?.bg_color
        }}>
            <Button style={{
                backgroundColor: "#000000",
                color: "#fff",
                width: "70%",
                position: "fixed",
                bottom: "4%",
                left: 0,
                right: 0,
                margin: "auto"
            }} onClick={() => {
                if (!fashionId) {
                    Toast.fail("款式库无数据")
                    return
                }
                window.location.replace(`/design/${fashionId}`)
            }}>我要定制</Button>
        </div>
    )
}

export default Index