import React, { useContext, useRef, useState } from 'react';
import { MenuType } from './menu'
import Pattern, { Action as PatternAction, Height as PatternHeight } from './pattern'
import Fashion, { Action as FashionAction } from './fashion'
import Cutpart, { Action as CutpartAction, Height as CutpartHeight } from './cutpart'
import Text, { Action as TextAction, Height as TextHeight } from './text'
import ActionTitle, { Action as ActionTitleAction, Height as ActionTitleHeight, Events } from './action_title'
import Graffiti, { Action as GraffitiAction, Height as GraffitiHeight } from './graffiti'
import Info, { Height as InfoHeight, Bottom as InfoBottom, Action as InfoAction } from './info'
import Head, { Height as HeadHeight, Action as HeadAction } from './head'
import Context from './context'
import TContext from '../context';



interface Props {
    Actions?: any
    children?: any
}

export const Index: React.FC<Props> = (props: Props) => {
    const [notEmptyCodes, setInnerNotEmptyCodes] = useState<any[]>([])
    const tc = useContext(TContext)
    const actionTitleAc = useRef<ActionTitleAction>()
    const partternAc = useRef<PatternAction>()
    const cutpartAc = useRef<CutpartAction>()
    const graffitiAc = useRef<GraffitiAction>()
    const textAc = useRef<TextAction>()
    const headAc = useRef<HeadAction>()
    const fashionAc = useRef<FashionAction>()
    const infoAc = useRef<InfoAction>()

    //显示一级组件
    const showPlugin = (key: MenuType) => {
        var safeHeight
        var fabricW
        tc?.onEditMode(key)
        switch (key) {
            case MenuType.WENZI:
                //显示actionTitle
                actionTitleAc.current?.setShow!(true)
                //设置actionTitle标题
                actionTitleAc.current?.setTitle!("文字")
                //隐藏部分选择器
                tc?.setShowTitle!(false)
                //缓存所有数据
                tc?.fabricClient?.cacheAllData()
                //设置3D高度
                safeHeight = tc.safeHeigth as number
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - TextHeight - safeHeight - HeadHeight - ActionTitleHeight)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!(safeHeight + TextHeight)
                //显示文字编辑区
                textAc.current?.setShow!(true)
                //注册事件
                actionTitleAc.current?.registEvent!(Events.CONFIRM_TEXT)
                return
            case MenuType.TUYA:
                //显示actionTitle
                actionTitleAc.current?.setShow!(true)
                //设置actionTitle标题
                actionTitleAc.current?.setTitle!("涂鸦")
                //隐藏部分选择器
                tc?.setShowTitle!(false)
                //缓存所有数据
                tc?.fabricClient?.cacheAllData()
                //显示裁片区
                cutpartAc?.current?.setShow!(true)
                //显示涂鸦菜单
                graffitiAc?.current?.setShow!(true)
                //设置3D高度
                safeHeight = tc.safeHeigth as number
                fabricW = tc.fabricW as number
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - (GraffitiHeight + safeHeight) - CutpartHeight - fabricW - HeadHeight - ActionTitleHeight)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!((GraffitiHeight + safeHeight) + fabricW + CutpartHeight)
                //设置裁片区bottom
                cutpartAc?.current?.setBottom!(GraffitiHeight + safeHeight)
                //裁片区不用安全高度
                cutpartAc?.current?.setUseSafeHeight!(false)
                //注册事件
                actionTitleAc.current?.registEvent!(Events.CONFIRM_GRAFFITI)
                return
            case MenuType.SUCAI:
                //显示actionTitle
                actionTitleAc.current?.setShow!(true)
                //设置actionTitle标题
                actionTitleAc.current?.setTitle!("选择素材")
                //注册事件
                actionTitleAc.current?.registEvent!(Events.QUEREN_SUCAI)
                //隐藏部分选择器
                tc?.setShowTitle!(false)
                //缓存所有裁片数据
                tc?.fabricClient?.cacheAllData()
                //设置3D区域高度
                fabricW = tc?.fabricW as number
                safeHeight = tc.safeHeigth as number
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - PatternHeight - HeadHeight - fabricW - safeHeight - ActionTitleHeight)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!(PatternHeight + fabricW + safeHeight)
                //设置裁片的bottom
                cutpartAc.current?.setBottom!(0)
                //裁片区使用安全高度
                cutpartAc.current?.setUseSafeHeight!(true)
                //素材区域显示
                partternAc?.current?.setShow!(true)
                return
            case MenuType.KUANSHI:
                //显示actionTitle
                actionTitleAc.current?.setShow!(true)
                //设置actionTitle标题
                actionTitleAc.current?.setTitle!("选择款式")
                //注册事件
                actionTitleAc.current?.registEvent!(Events.CONFIRM_FASHION)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!(document.body.clientHeight / 2 - ActionTitleHeight)
                //素材区域显示
                fashionAc?.current?.setShow!(true)
                return
        }
    }

    //3D区域初始的高度
    const get3DinitHeight = () => {
        const menuHeight = tc.menuHeight as number
        const canvas3DHeight = document.body.clientHeight - InfoHeight - menuHeight - InfoBottom - HeadHeight - 15
        return canvas3DHeight
    }

    //监听对象变动
    // useEffect(() => {
    //     if (!tc.objectModified) return;
    //     headAc.current?.addStep!()
    // }, [tc.objectModified])

    //子操作完成
    const onSubComplete = (em: Events) => {
        var fabricW
        var safeHeight
        switch (em) {
            case Events.CONFIRM_TEXT:
                //确认
                if (!textAc.current?.confirm!()) {
                    return
                }
                //更改actionTitle标题
                actionTitleAc.current?.setTitle!("编辑文字纹样")
                //显示裁片区
                cutpartAc.current?.setShow!(true)
                //注册事件，这里复用纹样编辑事件
                actionTitleAc.current?.registEvent!(Events.EDIT_PATTERN)
                //设置3D区域高度
                fabricW = tc?.fabricW as number
                safeHeight = tc.safeHeigth as number
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - CutpartHeight - HeadHeight - fabricW - safeHeight - ActionTitleHeight)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!(CutpartHeight + fabricW + safeHeight)
                //设置裁片区的bottom
                cutpartAc.current?.setBottom!(0)
                //裁片区使用安全高度
                cutpartAc.current?.setUseSafeHeight!(true)
                break
            case Events.QUEREN_SUCAI:
                //确认
                if (!partternAc.current?.confirm!()) {
                    return
                }
                //更改actionTitle标题
                actionTitleAc.current?.setTitle!("编辑纹样")
                //显示裁片区
                cutpartAc.current?.setShow!(true)
                //注册事件
                actionTitleAc.current?.registEvent!(Events.EDIT_PATTERN)
                //设置3D区域高度
                fabricW = tc?.fabricW as number
                safeHeight = tc.safeHeigth as number
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - CutpartHeight - HeadHeight - fabricW - safeHeight - ActionTitleHeight)
                //设置actiontitle的bottom
                actionTitleAc.current?.setBottom!(CutpartHeight + fabricW + safeHeight)
                break
            case Events.EDIT_PATTERN:
                //关闭裁片区
                cutpartAc.current?.setShow!(false)
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //活动的对象失去焦点
                tc?.curCanvasClient?.discardActiveObject()
                //显示部分选择器
                tc?.setShowTitle!(true)
                addStep()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.CONFIRM_GRAFFITI:
                //关闭裁片区
                cutpartAc.current?.setShow!(false)
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //显示部分选择器
                tc?.setShowTitle!(true)
                //调用内部完成方法
                graffitiAc.current?.confirm!()
                addStep()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.CONFIRM_FASHION:
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                fashionAc.current?.confirm!()
                return
        }
        checkNotEmptyCodes()
    }

    //子操作取消
    const onSubCancel = (em: Events) => {
        switch (em) {
            case Events.CONFIRM_TEXT:
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //显示部分选择器
                tc?.setShowTitle!(true)
                //重置缓存数据
                tc?.fabricClient?.resetDataFromCache()
                //调用内部取消方法
                textAc.current?.cancel!()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.QUEREN_SUCAI:
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //显示部分选择器
                tc?.setShowTitle!(true)
                //重置缓存数据
                tc?.fabricClient?.resetDataFromCache()
                //调用内部取消方法
                partternAc.current?.cancel!()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.EDIT_PATTERN:
                //关闭裁片区
                cutpartAc.current?.setShow!(false)
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //活动的对象失去焦点
                tc?.curCanvasClient?.discardActiveObject()
                //显示部分选择器
                tc?.setShowTitle!(true)
                //重置缓存数据
                tc?.fabricClient?.resetDataFromCache()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.CONFIRM_GRAFFITI:
                //关闭裁片区
                cutpartAc.current?.setShow!(false)
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                //设置3D区域高度
                tc?.threeClient?.setCanvasSize(document.body.clientWidth, get3DinitHeight())
                //显示部分选择器
                tc?.setShowTitle!(true)
                //重置缓存数据
                tc?.fabricClient?.resetDataFromCache()
                //调用内部取消方法
                graffitiAc.current?.cancel!()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                break
            case Events.CONFIRM_FASHION:
                //隐藏actionTitle
                actionTitleAc.current?.setShow!(false)
                fashionAc.current?.cancel!()
                //清空事件
                actionTitleAc.current?.registEvent!(Events.NONE)
                return
        }
        checkNotEmptyCodes()
    }

    const onDelCutpartObject = () => {
        const obj = tc?.curCanvasClient?.getFabricCanvas().getActiveObject()
        if (obj) {
            tc?.curCanvasClient?.getFabricCanvas().remove(obj)
            tc?.curCanvasClient?.getFabricCanvas().renderAll()
        }
    }

    const onEditText = () => {
        //关闭裁片区
        cutpartAc.current?.setShow!(false)
        //显示actionTitle
        actionTitleAc.current?.setShow!(true)
        //设置actionTitle标题
        actionTitleAc.current?.setTitle!("文字")
        //隐藏部分选择器
        tc?.setShowTitle!(false)
        //缓存所有数据
        tc?.fabricClient?.cacheAllData()
        //设置3D高度
        var safeHeight = tc.safeHeigth as number
        tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - TextHeight - safeHeight - HeadHeight - ActionTitleHeight)
        //设置actiontitle的bottom
        actionTitleAc.current?.setBottom!(safeHeight + TextHeight)
        //显示文字编辑区
        textAc.current?.editShow!()
        //注册事件
        actionTitleAc.current?.registEvent!(Events.CONFIRM_TEXT)
    }

    //打开裁片区
    const openCutpart = () => {
        //隐藏部分选择器
        tc?.setShowTitle!(false)
        //缓存所有数据
        tc?.fabricClient?.cacheAllData()
        //设置3D高度
        var safeHeight = tc.safeHeigth as number
        var fabricW = tc.fabricW as number
        tc?.threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight - fabricW - safeHeight - CutpartHeight - HeadHeight - ActionTitleHeight)
        actionTitleAc.current?.setShow!(true)
        actionTitleAc.current?.setTitle!("编辑纹样")
        //设置actiontitle的bottom
        actionTitleAc.current?.setBottom!(safeHeight + fabricW + CutpartHeight)
        //设置裁片区的bottom
        cutpartAc.current?.setBottom!(0)
        //裁片区使用安全高度
        cutpartAc.current?.setUseSafeHeight!(true)
        //裁片区显示
        cutpartAc.current?.setShow!(true)
        //注册事件
        actionTitleAc.current?.registEvent!(Events.EDIT_PATTERN)
    }

    //撤销埋点
    const addStep = (j = null) => {
        headAc.current?.addStep!(j)
    }

    //检查所有裁片是否为空
    const checkNotEmptyCodes = () => {
        setInnerNotEmptyCodes(tc?.fabricClient?.isEmptyAll())
    }

    //下单
    const doOrder = () => {
        infoAc.current?.setShowPrice!(true)
        infoAc.current?.setShowSubmitOrder!(true)
    }


    return (
        <Context.Provider value={{
            showPlugin,
            onSubComplete,
            onSubCancel,
            onDelCutpartObject,
            onEditText,
            openCutpart,
            addStep,
            checkNotEmptyCodes,
            notEmptyCodes,
            doOrder
        }}>
            <Head
                action={headAc}
            />
            {props.children}
            <ActionTitle
                action={actionTitleAc}
            />
            <Pattern
                action={partternAc}
            />
            <Cutpart
                action={cutpartAc}
            />
            <Graffiti
                action={graffitiAc}
            />
            <Text
                action={textAc}
            />
            <Fashion
                action={fashionAc}
            />
            <Info
                action={infoAc}
            />
        </Context.Provider>
    )
}

export default Index 