import { useEffect, useState } from "react"
export interface Props {
    progress?: number
}

const Index = (props: Props) => {
    const [show, setShow] = useState<boolean>(true)

    useEffect(() => {
        if (props?.progress as number >= 100) {
            setTimeout(() => { setShow(false) }, 1500)
        }
    }, [props.progress])

    return (
        <div className="displayAnimate" style={{
            position: "fixed",
            top: 0,
            left: 0,
            background: "#FFFFFF",
            zIndex: 99,
            bottom: 0,
            right: 0,
            margin: "auto",
            opacity: show ? 1 : 0,
            pointerEvents: show ? "auto" : "none"
        }}>
            <div style={{
                fontWeight: 500,
                fontSize: 16,
                color: "#000000",
                textAlign: "center",
                height: 30,
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto"
            }}>
                <div>{parseInt(props.progress as any) || 0}%</div>
            </div>
        </div>
    )
}

export default Index