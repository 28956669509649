
export const maps = [
    {
        "label": "法线贴图（normalMap）",
        "value": "normalMap"
    },
    {
        "label": "光照贴图（aoMap）",
        "value": "aoMap"
    },
    {
        "label": "灰度贴图（alphaMap）",
        "value": "alphaMap"
    },
    {
        "label": "粗糙度贴图（roughnessMap）",
        "value": "roughnessMap"
    },
    {
        "label": "金属度贴图（metalnessMap）",
        "value": "metalnessMap"
    },
    {
        "label": "位移贴图（displacementMap）",
        "value": "displacementMap"
    },
    {
        "label": "发光贴图（emissiveMap）",
        "value": "emissiveMap"
    }
]