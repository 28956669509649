import { useEffect, useRef } from "react"

const Index = (props: any) => {
    const { show = false, showType = "block", ...others } = props
    const dom = useRef()

    useEffect(() => {
        var c: any = dom?.current
        if (show) {
            c.style.display = showType
            c.style.opacity = 0
            setTimeout(() => {
                c.style.opacity = 1
            }, 300)
        } else {
            c.style.opacity = 0
            setTimeout(() => {
                c.style.display = "none"
            }, 300)
        }
        //eslint-disable-next-line
    }, [show])

    return (
        <div className="displayAnimate" ref={dom} {...others}>
            {
                props.children
            }
        </div>
    )
}

export default Index