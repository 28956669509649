import YesIcon from '../../../../static/img/yes.png'
import DeleteIcon from '../../../../static/img/delete.png'
import { useState, useImperativeHandle, useContext } from 'react';
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'


export enum Events {
    NONE = 0, //无事件
    QUEREN_SUCAI = 1,
    EDIT_PATTERN = 2,
    CONFIRM_GRAFFITI = 3, //确认涂鸦
    CONFIRM_TEXT = 4, //确认文字
    CONFIRM_TEXT_PATTERN = 5, //确认文字纹样
    CONFIRM_FASHION = 6 //确认款式
}


export interface Action {
    setShow?: (s: boolean) => void; //显示
    setTitle?: (s: string) => void; //设置title文字
    registEvent?: (s: Events) => void; //注册事件
    setBottom?: (s: number) => void; //设置bottom
}

export interface Props {
    action?: any
}

export const Height = 30


const Index = (props: Props) => {
    const [show, setShow] = useState<boolean>(false)
    const [bottom, setBottom] = useState<number>(0)
    const [title, setTitle] = useState<string>("编辑纹样")
    const [event, setEvent] = useState<Events>()
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    const registEvent = (s: Events) => {
        tc?.setCurEvent!(s) //通知到全局
        setEvent(s)
    }

    useImperativeHandle(props.action, () => ({
        setShow,
        setTitle,
        registEvent,
        setBottom
    }));


    //确认
    const confirm = (e: any) => {
        e.preventDefault();
        pc?.onSubComplete!(event as Events)
    }

    //取消
    const cancel = (e: any) => {
        e.preventDefault();
        pc?.onSubCancel!(event as Events)
    }

    return (
        <Animate show={show} showType="flex" style={{
            height: Height,
            background: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            position: "fixed",
            left: 0,
            display: "flex",
            width: "100%",
            bottom,
            // boxShadow: "rgb(0 0 0 / 10%) 0px -1px 5px 0px",
            borderRadius: "12px 12px 0px 0px",
            zIndex: 99
        }}>
            <div style={{
                padding: "0 5px",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: "100%"
            }}>
                <div style={{
                    width: Height,
                    height: Height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={cancel}>
                    <img style={{
                        width: 13,
                        height: 13
                    }} src={DeleteIcon} alt="" />
                </div>
                <div style={{
                    fontSize: 14,
                    fontWeight: 500
                }}>{title}</div>
                <div style={{
                    width: Height,
                    height: Height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={confirm}>
                    <img style={{
                        width: 18,
                        height: 14
                    }} src={YesIcon} alt="" />
                </div>
            </div>
        </Animate>

    )
}

export default Index