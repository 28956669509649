import BackhomeIcon from '../../static/img/backhome.png'
import SuccessIcon from '../../static/img/success_icon.png'
import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash';
console.log(QRCode)

const Index = () => {
    const [qrcodeUrl, setQrcodeUrl] = useState<string>()

    useEffect(() => {
        const h = cloneDeep(window.location.href)
        QRCode.toDataURL(h.replaceAll("success", "preview"), { with: 218 }).then((url: string) => {
            setQrcodeUrl(url)
        }).catch((err: any) => {
            console.error(err)
        })
    }, [])

    return (
        <div>
            <div style={{
                height: 50,
                display: "flex",
                alignItems: "center"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 20px",
                    margin: "0 auto",
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div onClick={() => window.location.replace("/")} style={{ marginRight: 12 }}>
                            <img src={BackhomeIcon} style={{
                                width: 12,
                                display: "block"
                            }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                marginTop: "20%"
            }}>
                <img style={{
                    width: 80,
                    height: 80,
                    margin: "0 auto",
                    marginBottom: 6
                }} src={SuccessIcon} alt="" />
                <div style={{
                    color: "#333",
                    textAlign: "center"
                }}>提交成功</div>
            </div>
            <div>
                <img src={qrcodeUrl} style={{
                    width: 218,
                    height: 218,
                    margin: "0 auto"
                }} alt="" />
                <div style={{
                    color: "#cccccc",
                    fontSize: 12,
                    textAlign: "center"
                }}>长按识别可查看设计效果</div>
            </div>
        </div>
    )
}

export default Index