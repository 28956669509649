import { useContext, useImperativeHandle, useState } from "react";
// import patternClose from '../../../../static/img/pattern_close.png'
import patternClose from '../../../../static/img/pattern_close2.png'
import patternScale from '../../../../static/img/pattern_scale2.png'
// import patternRote from '../../../../static/img/pattern_rote.png'
import patternRote from '../../../../static/img/rote.png'
import textEdit from '../../../../static/img/txt_edit.png'
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'

const cutpartBoxStyle: React.CSSProperties = {
    background: '#ffffff',
    overflow: 'hidden',
    // border: "solid 1px rgba(0,0,0,0.1)"
    // position: 'relative'
}

const canvas2D: any = {
    display: 'block',
    margin: '0 auto',
    touchAction: 'none'
}

export interface Props {
    controPosition?: any
    action?: any
}

export interface Action {
    setShow?: (s: boolean) => void; //显示
    setBottom?: (s: number) => void; //设置底部距离
    setUseSafeHeight?: (s: boolean) => void; //是否使用安全高度
}

const paddingHeight = 10
export const Height = paddingHeight + paddingHeight


const Index = (props: Props) => {
    const [show, setInnerShow] = useState<boolean>(false)
    const [bottom, setBottom] = useState<number>(0)
    const [useSafeHeight, setUseSafeHeight] = useState<boolean>(true)

    const tc = useContext(TContext)
    const pc = useContext(PContext)


    useImperativeHandle(props.action, () => ({
        setShow,
        setBottom,
        setUseSafeHeight
    }));


    const setShow = (isShow: boolean) => {
        setInnerShow(isShow)
    }

    return (
        <Animate show={show} style={{
            width: "100%",
            position: "fixed",
            margin: '0 auto',
            zIndex: 11,
            bottom,
            paddingBottom: useSafeHeight ? 'env(safe-area-inset-bottom)' : 0,
            ...cutpartBoxStyle
        }}>
            <div style={{ height: paddingHeight }}></div>
            <div style={{
                width: tc.fabricW,
                height: tc.fabricW,
                position: 'relative',
                margin: '0 auto',
                overflow: "hidden"
            }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div>
                        {
                            tc?.detailData?.materials?.cutparts?.map((v: any) => (
                                <div key={v.code} style={{
                                    display: tc.curCutpartCode === v.code ? 'flex' : 'none',
                                    justifyContent: 'center',
                                    width: tc.fabricW,
                                    height: tc.fabricW,
                                    position: 'relative',
                                    margin: "0 auto",
                                    overflow: 'hidden',
                                }}>
                                    <canvas id={v.code} style={canvas2D}></canvas>
                                    <div style={{
                                        position: "absolute",
                                        width: tc.fabricW,
                                        height: tc.fabricW,
                                        left: 0,
                                        top: 0,
                                        pointerEvents: "none",
                                        background: `url(${process.env.REACT_APP_OSS_URL + v.img})`,
                                        backgroundSize: `100% 100%`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center"
                                    }} />
                                </div>
                            ))
                        }
                    </div>
                    {
                        tc.controPosition && <>
                            <img src={patternClose} onClick={pc?.onDelCutpartObject} style={{ height: 19, width: 19, zIndex: 9, position: 'absolute', ...tc?.controPosition?.tl_position }} alt="" />
                            <img src={patternScale} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...tc?.controPosition?.br_position }} alt="" />
                            <img src={patternRote} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...tc?.controPosition?.tr_position }} alt="" />
                            {
                                tc?.curActivityObject.type === "text" && <img src={textEdit} onClick={pc?.onEditText} style={{ height: 19, width: 19, zIndex: 9, position: 'absolute', ...tc?.controPosition?.bl_position }} alt="" />
                            }
                        </>
                    }
                </div>
            </div>
            <div style={{ height: paddingHeight }}></div>
        </Animate>
    )
}

export default Index