// import * as THREE from 'three/build/three.module.js';
import { Camera, Scene, AmbientLight, DirectionalLight, WebGLRenderer, Object3D, Group } from 'three'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

export interface Client3DProps {
    canvasDom: HTMLCanvasElement
    canvasWidth: number
    canvasHeight: number
}


class Client3D {

    private camera?: any
    private scene?: Scene
    private ambientLight?: AmbientLight
    private directionalLight_1?: DirectionalLight
    private directionalLight_2?: DirectionalLight
    private render?: WebGLRenderer
    canvasDom?: HTMLCanvasElement
    contron?: OrbitControls
    private rafId?: number
    private modelObject?: Object3D
    canvasWidth: number
    canvasHeight: number
    private object3Dchilds: any
    private composer: any //部件外圈阴影
    private outlinePass: any
    private effectFXAA: any
    private raycaster: any
    private modelFormat: string
    private isStopNeedsUpdate: boolean
    private onBeforeCompiles: any
    private outlinePassTimer: any

    constructor(s: Client3DProps) {
        this.modelFormat = ""
        this.rafId = 0
        this.isStopNeedsUpdate = false
        this.canvasDom = s.canvasDom
        this.canvasWidth = s.canvasWidth || 300
        this.canvasHeight = s.canvasHeight || 300
        this.object3Dchilds = {}
        this.onBeforeCompiles = {}
        this.raycaster = new THREE.Raycaster();
        this.outlinePassTimer = null
        this.initCamera()
        this.initScene()
        this.initRender()
        this.initComposer()
    }

    //初始化相机
    private initCamera(): void {
        this.camera = new THREE.PerspectiveCamera(45, this.canvasWidth / this.canvasHeight, 0.25, 2000) as Camera;
        this.camera.aspect = this.canvasWidth / this.canvasHeight
        this.camera.zoom = 1.5
        this.camera.updateProjectionMatrix()
    }

    //获取canvasdom
    getCanvasDom() {
        return this.render?.domElement
    }

    //初始化场景
    private initScene(): void {
        this.scene = new THREE.Scene()

        this.scene.background = new THREE.Color(0xcccccc);
        // this.scene.fog = new THREE.Fog(0xcccccc, 1, 6);

        this.ambientLight = new THREE.AmbientLight(0xffffff, 0.6)
        this.scene.add(this.ambientLight);

        this.directionalLight_1 = new THREE.DirectionalLight(0xffffff, 0.4);
        this.directionalLight_1.position.set(0, 0.5, 0.5);

        this.scene.add(this.directionalLight_1);

        this.directionalLight_2 = new THREE.DirectionalLight(0xffffff, 0.4);
        this.directionalLight_2.position.set(0, 0, -0.5);
        this.scene.add(this.directionalLight_2);

        const dirLight = new THREE.DirectionalLight(0xffffff, 0.1);
        dirLight.position.set(0, 1, 0);
        dirLight.shadow.mapSize = new THREE.Vector2(2500, 2500)
        dirLight.castShadow = true;
        // dirLight.shadow.camera.top = 180;
        // dirLight.shadow.camera.bottom = - 100;
        // dirLight.shadow.camera.left = - 120;
        // dirLight.shadow.camera.right = 120;
        this.scene.add(dirLight);

        const mesh = new THREE.Mesh(new THREE.PlaneGeometry(5, 5), new THREE.MeshPhongMaterial({ color: 0xcccccc, depthWrite: false }));
        mesh.rotation.x = - Math.PI / 2;
        mesh.receiveShadow = true;
        mesh.position.y = -0.3
        this.scene.add(mesh);

        // const grid: any = new THREE.GridHelper(5, 30, 0x000000, 0x000000);
        // grid.position.y = -0.3
        // grid.material.opacity = 1;
        // grid.material.transparent = true;
        // this.scene.add(grid);

        // const size = 5;
        // const divisions = 10;

        // const gridHelper = new THREE.GridHelper(size, divisions);
        // this.scene.add(gridHelper);

    }

    //初始化渲染
    private initRender(): void {
        this.render = new THREE.WebGLRenderer({
            canvas: this.canvasDom,
            preserveDrawingBuffer: true,
            antialias: true,
            // alpha: true
        });
        this.render.setPixelRatio(window.devicePixelRatio);
        this.render.setSize(this.canvasWidth, this.canvasHeight);
        this.render.shadowMap.enabled = true;
    }

    //启动场景
    start(): void {
        this.contron = new OrbitControls(this.camera as Camera, this.canvasDom);
        this.contron.enableDamping = true
        this.contron.enablePan = false
        this.contron.target.set(0, 0.1, 0);
        this.loopRender()
    }

    //设置canvas大小
    setCanvasSize(width: number = document.body.clientWidth, height: number = document.body.clientHeight): void {
        this.canvasWidth = width
        this.canvasHeight = height
        this.render!.setSize(width, height);
        this.camera.aspect = width / height
        this.camera.zoom = 1.5
        this.camera.updateProjectionMatrix()

        this.composer.setSize(width, height);
        this.effectFXAA.uniforms['resolution'].value.set(1 / width, 1 / height);
    }

    //循环渲染
    private loopRender(): void {
        this.contron?.update();
        this.rafId = requestAnimationFrame(() => {
            this.loopRender()
        })
        this.composer.render();
        //this.render?.render(this.scene as Object3D, this.camera as Camera)
    }

    stopNeedsUpdate() {
        this.isStopNeedsUpdate = true
    }

    //清除法线纹理
    clearNormalMap(type = "normalMap", color = "#ffffff") {
        for (let name in this.object3Dchilds) {
            let mesh = this.object3Dchilds[name]
            this.onBeforeCompiles = {}
            mesh.material.onBeforeCompile = () => { }
            mesh.material[type] = null
            mesh.material.color.set(color)
            mesh.material.needsUpdate = true;
            this.object3Dchilds[mesh.name] = mesh
        }
    }


    private action() {
        let _this = this
        return {
            //根据name设置图片Texture
            setMapImageTextureByName(name: string, url: string) {
                return new Promise((resolve, reject) => {
                    const loader = new THREE.TextureLoader()
                    const texture = loader.load(url)
                    let object = _this.object3Dchilds[name]
                    object.material.map = texture
                    object.material.map.needsUpdate = true
                    resolve(null)
                })

            },

            //根据name设置mapTexture
            setMapCanvasTextureByName(name: string, canvas: HTMLCanvasElement, repeat: number) {
                let object = _this.object3Dchilds[name]
                let texture = new THREE.CanvasTexture(canvas)
                object.material.map = texture
                object.material.map.minFilter = THREE.LinearFilter;
                if (_this.modelFormat === "glb") {
                    object.material.map.flipY = false;
                }
                object.material.map.needsUpdate = true

                return {
                    //更新map
                    needsUpdate(timeout: number = 0) {
                        let object = _this.object3Dchilds[name]
                        if (object && !_this.isStopNeedsUpdate) {
                            setTimeout(() => { object.material.map.needsUpdate = true }, timeout)
                        }
                    },

                    //选择部位效果
                    activate() {
                        //console.log("name", name)
                        let object = _this.object3Dchilds[name]
                        _this.outlinePass.selectedObjects = [object];
                        _this.outlinePass.pulsePeriod = 1
                        _this.outlinePass.enabled = true
                        if (!_this.outlinePassTimer) {
                            _this.outlinePassTimer = setTimeout(() => {
                                _this.outlinePass.pulsePeriod = 0
                                _this.outlinePass.enabled = false
                                _this.outlinePassTimer = null
                            }, 2000)
                        }
                    }
                }
            },
            //设置法线纹理
            setNormalMap(url: string, type = "normalMap", color: string = "#ffffff", code = "") {
                let hand = (shader: any) => {
                    //console.log(shader.fragmentShader);
                    shader.fragmentShader = shader.fragmentShader.replace("#include <normal_fragment_maps>",
                        `vec3 mapN=texture2D( normalMap, vUv*vec2(6.0,2.0) ).xyz * 2.0 - 1.0;
                    mapN.xy *= normalScale;
                    normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
                    `)
                }
                if (!url) {
                    return new Promise((resolve, reject) => {
                        // for (let name in _this.object3Dchilds) {
                        //     let mesh = _this.object3Dchilds[name]
                        //     mesh.material.removeEventListener('beforeCompile', hand);
                        //     delete mesh.material[type]
                        //     mesh.material.color.set(color)
                        //     mesh.material.needsUpdate = true;
                        //     _this.object3Dchilds[mesh.name] = mesh
                        // }
                        resolve(null)
                    })
                }
                return new Promise((resolve, reject) => {
                    if (!code) {
                        resolve(null)
                        return
                    }
                    const loader = new THREE.TextureLoader()
                    loader.load(process.env.REACT_APP_OSS_URL + url, (texture) => {
                        let mesh = _this.object3Dchilds[code]
                        texture.wrapS = THREE.RepeatWrapping;
                        texture.wrapT = THREE.RepeatWrapping;
                        mesh.material[type] = texture
                        mesh.material.color.set(color)
                        mesh.material.needsUpdate = true
                        if (!_this.onBeforeCompiles[code] && type == "normalMap") {
                            mesh.material.onBeforeCompile = hand;
                            _this.onBeforeCompiles[code] = 1
                        }

                        _this.object3Dchilds[code] = mesh
                        resolve(null)
                    }, () => { }, () => {
                        reject()
                    })
                })
            }
        }
    }

    //设置摄像机位置
    setCamera(x: number, y: number, z: number) {
        this.camera.position.set(x, y, z)
    }

    //加载模型，自动区分格式
    loadModel(path: string, onProgress: Function) {
        if (path.indexOf(".obj") !== -1) {
            this.modelFormat = "obj"
            return this.loadObj(path, onProgress)
        } else {
            this.modelFormat = "glb"
            return this.loadGlb(path, onProgress)
        }
    }

    //加载glb文件
    loadGlb(path: string, onProgress: Function) {
        return new Promise((resolve, reject) => {
            new GLTFLoader().load(path, async (objects: GLTF) => {
                let group: Group = objects.scene
                this.modelObject = group
                this.scene?.add(group)
                this.setContentCenter(group)
                group.traverse(async (child: any) => {
                    if (child.isMesh) {
                        // const loader = new THREE.TextureLoader()
                        // const texture = loader.load('https://file-1308172012.cos.ap-beijing.myqcloud.com/objs/fx.png')
                        // texture.magFilter = THREE.NearestFilter;
                        // texture.minFilter = THREE.LinearFilter;
                        // texture.needsUpdate = true
                        child.material = new THREE.MeshPhysicalMaterial({
                            side: THREE.DoubleSide,
                            // normalMap: texture,
                        })
                        this.object3Dchilds[child.name] = child
                    }
                })
                resolve(this.action())
                // setTimeout(() => ), 500)
            }, xhr => {
                let p = (xhr.loaded / xhr.total) * 100;
                onProgress(p.toFixed(2))
            }, error => {
                reject(error)
            })
        })
    }

    //加载obj文件
    loadObj(path: string, onProgress: Function) {
        return new Promise((resolve, reject) => {
            new OBJLoader().load(path, async (objects) => {
                this.modelObject = objects
                this.scene?.add(objects)
                this.setContentCenter(objects)
                for (let i = 0; i < objects.children.length; i++) {
                    let child: any = objects.children[i]
                    if (child.isMesh) {
                        child.material = new THREE.MeshPhysicalMaterial({
                            side: THREE.DoubleSide
                        })
                        child.castShadow = true;
                        child.receiveShadow = false;

                        this.object3Dchilds[child.name] = child
                    }
                }
                resolve(this.action())
                // setTimeout(() => ), 500)
            }, xhr => {
                let p = (xhr.loaded / xhr.total) * 100;
                onProgress(p.toFixed(2))
            }, error => {
                reject(error)
            })
        })
    }



    //销毁
    destroy() {
        this.scene?.remove(this.modelObject as Object3D)
        this.contron?.dispose()
        cancelAnimationFrame(this.rafId as number)
    }

    //模型垂直居中
    private setContentCenter(object: Object3D) {
        object.updateMatrixWorld();
        const box = new THREE.Box3().setFromObject(object);
        // const size = box.getSize(new THREE.Vector3()).length();
        const boxSize = box.getSize(new THREE.Vector3());
        const center = box.getCenter(new THREE.Vector3());
        object.position.x = object.position.x - center.x;
        object.position.y = object.position.y - center.y + 0.1;//修改center.y可以设置模型整体上下偏移
        object.position.z = object.position.z - center.z;
        this.camera?.position.copy(center);
        if (boxSize.x > boxSize.y) {
            this.camera!.position.z = boxSize.x * 2.85;
        } else {
            this.camera!.position.z = boxSize.y * 2.85;
        }
        this.camera?.lookAt(0, 0, 0);
    }

    //初始化选择部位的阴影效果
    private initComposer() {
        this.composer = new EffectComposer(this.render as any);
        const renderPass = new RenderPass(this.scene as any, this.camera as any);
        this.composer.addPass(renderPass);
        this.outlinePass = new OutlinePass(new THREE.Vector2(this.canvasWidth, this.canvasHeight), this.scene as any, this.camera as any);
        // this.outlinePass.edgeStrength = 4.6;
        // this.outlinePass.visibleEdgeColor.set("rgb(245, 119, 102)");
        // this.outlinePass.hiddenEdgeColor.set("#5336dc");
        // this.outlinePass.edgeThickness = 4.4
        // this.outlinePass.edgeGlow = 1
        // this.outlinePass.pulsePeriod = 1
        this.outlinePass.edgeStrength = 4;
        this.outlinePass.visibleEdgeColor.set(new THREE.Color(0x1677ff, 1.0, 0));
        this.outlinePass.hiddenEdgeColor.set(new THREE.Color(0x1677ff, 1.0, 0));
        this.outlinePass.edgeThickness = 1
        this.outlinePass.edgeGlow = 1


        this.composer.addPass(this.outlinePass);
        this.effectFXAA = new ShaderPass(FXAAShader);
        this.effectFXAA.uniforms['resolution'].value.set(1 / this.canvasWidth, 1 / this.canvasHeight);
        this.composer.addPass(this.effectFXAA);
        // this.render?.domElement.addEventListener('pointermove', (event: any) => {
        //     if (event.isPrimary === false) return;
        //     const mouse: any = {}

        //     mouse.x = (event.clientX / this.canvasWidth) * 2 - 1;
        //     mouse.y = - (event.clientY / this.canvasHeight) * 2 + 1;

        //     this.raycaster.setFromCamera(mouse, this.camera);
        //     const intersects = this.raycaster.intersectObject(this.scene, true);
        //     if (intersects.length > 0) {
        //         const selectedObject = intersects[0].object;
        //         const selectedObjects = [];
        //         selectedObjects.push(selectedObject);
        //         this.outlinePass.selectedObjects = selectedObjects;
        //     } else {
        //         this.outlinePass.selectedObjects = [];
        //     }
        // });
    }

    //截图
    public screePhoto() {
        return new Promise((resolve, reject) => {
            this.render?.domElement.toBlob(async (file) => {
                resolve(file)
            })
        })
    }

}

export default Client3D