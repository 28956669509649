import { useEffect, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../pages/home'
import IndexPage from '../pages/index'
import Success from '../pages/success'
import TestPage from '../pages/testpage'
// import Preview3D from '../pages/preview3D'


// const Home = lazy(() => import('../pages/home'));
const Preview3D = lazy(() => import('../pages/preview3D'));
// const BridgeContext = React.createContext(undefined)


const Index: React.FunctionComponent = () => {

    useEffect(() => { }, [])

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/design/:id" element={<Home />} />
                    <Route path="/preview/:directory/:name" element={<Preview3D />} />
                    <Route path="/success/:directory/:name" element={<Success />} />
                    <Route path="/ttt" element={<TestPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Index
