import { useEffect } from "react"
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
let camera: any, scene: any, renderer: any;
const Index = () => {
    useEffect(() => {




        function init() {


            camera = new THREE.PerspectiveCamera(45, 375 / 492, 1, 2000);
            camera.position.set(100, 200, 300);

            scene = new THREE.Scene();
            scene.background = new THREE.Color(0xa0a0a0);
            scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

            const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
            hemiLight.position.set(0, 200, 0);
            scene.add(hemiLight);

            const dirLight = new THREE.DirectionalLight(0xffffff);
            dirLight.position.set(0, 200, 100);
            dirLight.castShadow = true;
            dirLight.shadow.camera.top = 180;
            dirLight.shadow.camera.bottom = - 100;
            dirLight.shadow.camera.left = - 120;
            dirLight.shadow.camera.right = 120;
            scene.add(dirLight);

            // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );

            // ground
            const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
            mesh.rotation.x = - Math.PI / 2;
            mesh.receiveShadow = true;
            scene.add(mesh);

            const grid: any = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
            grid.material.opacity = 0.2;
            grid.material.transparent = true;
            scene.add(grid);


            renderer = new THREE.WebGLRenderer({ antialias: true, canvas: document.getElementById("aabb") as HTMLCanvasElement });
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(375, 492);
            renderer.shadowMap.enabled = true;


            const controls = new OrbitControls(camera, renderer.domElement);
            controls.target.set(0, 100, 0);
            controls.update();

        }


        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        init();
        animate();
    }, [])

    return (<canvas id="aabb"></canvas>)
}

export default Index