
import React from 'react'
import { MenuType } from './menu'
import { Events } from './action_title'

interface Context {
    showPlugin?: (em: MenuType) => void; //显示插件
    onEditText?: any //触发编辑文字
    onDelCutpartObject?: () => void; //删除裁片对象
    onSubComplete?: (em: Events) => void; //子操作完成
    onSubCancel?: (em: Events) => void; //子操作取消
    openCutpart?: () => void; //打开裁片区
    addStep?: (j: any) => void; //撤销步骤添加
    notEmptyCodes?: any[]; //不为空的裁片codes
    checkNotEmptyCodes?: () => void;
    doOrder?: () => void; //下单
}

export default React.createContext<Context>({})