export const DATA_DETAIL = `${process.env.REACT_APP_API_HOST}/client/open/product/detail`
export const PRODUCT_CATEGORY_LIST = `${process.env.REACT_APP_API_HOST}/client/open/product/category/list`
export const PRODUCT_LIST = `${process.env.REACT_APP_API_HOST}/client/open/product/list`
export const PATTERN_CATEGORY_LIST = `${process.env.REACT_APP_API_HOST}/client/open/pattern/category/list`
export const PATTERN_LIST = `${process.env.REACT_APP_API_HOST}/client/open/pattern/list`
export const FONT_LIST = `${process.env.REACT_APP_API_HOST}/client/open/font/list`
export const ALI_STS = `${process.env.REACT_APP_API_HOST}/client/open/sts/token`
export const ORDER_CREATE = `${process.env.REACT_APP_API_HOST}/client/open/order/create`
export const INDEX_DATA = `${process.env.REACT_APP_API_HOST}/client/open/index/data`



export const UUID = `${process.env.REACT_APP_API_HOST}/uuid`





