import { useState, useImperativeHandle } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import TContext from '../../context'
// import PContext from '../context'
import Animate from '../../../animate'
import { Height as ActionTitleHeight } from '../action_title'
import { Toast, Empty, Infiniteloading, Tabs } from '@nutui/nutui-react';
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import { cloneDeep } from 'lodash';

interface Props {
    action?: any
}

export interface Action {
    setShow?: (isShow: boolean) => void;
    confirm?: () => boolean;
    cancel?: () => void;
}

const Index = (props: Props) => {
    const [show, setInnerShow] = useState(false)
    const [page, setPage] = useState(1)
    const [curFashion, setCurFashion] = useState<any>({})
    const [productGroups, setproductGroups] = useState<any>([])
    const [patternList, setPatternList] = useState({ list: [], total: 0 })
    const [curProductGroupId, setCurProductGroupId] = useState<any>(0)
    // const tc = useContext(TContext)
    // const pc = useContext(PContext)

    useImperativeHandle(props.action, () => ({
        setShow,
        confirm,
        cancel
    }));

    const loadProductList = async (categoryId: number, page: number) => {
        try {
            const patterns: any = await http.post(apis.PRODUCT_LIST, {
                page: page,
                page_size: 10,
                status: 1,
                category_id: categoryId
            })

            for (let i = 0; i < patterns?.list?.length; i++) {
                let item = patterns.list[i]
                if (item.materials) {
                    item.materials = JSON.parse(item.materials)
                }
                if (item.tags) {
                    item.tags = item.tags.split(",")
                }
                if (item.imgs) {
                    item.imgs = JSON.parse(item.imgs)
                }
            }

            if (page === 1) {
                setPatternList(patterns)
                return
            }
            let cpList: any = cloneDeep(patternList)
            cpList.total = patterns.total
            cpList.list = [...cpList.list, ...patterns.list]
            setPatternList(cpList)
        } catch (error) {
            console.log(error)
        }
    }

    const setShow = async (isShow: boolean) => {
        setInnerShow(isShow)
        if (isShow) {
            Toast.loading("加载中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })
            setPage(1)
            try {
                const productGroups: any = await http.post(apis.PRODUCT_CATEGORY_LIST, {
                    page: 1,
                    page_size: 100,
                    status: 1
                })
                productGroups?.list?.unshift({ id: 0, name: "全部" })
                setproductGroups(productGroups?.list)
                await loadProductList(0, 1)
                setCurProductGroupId(0)
            } catch (error) {

            }
            Toast.hide()
        }
    }

    const loadProduct = async (groupId: any) => {
        if (groupId === curProductGroupId) {
            return
        }
        setCurFashion({})
        setPage(1)
        await loadProductList(groupId, 1)
        setCurProductGroupId(groupId)
    }

    // //取消
    const cancel = () => {
        setInnerShow(false)
        setCurFashion({})
    }

    //确认
    const confirm = () => {
        if (!curFashion.id) {
            return false
        }
        window.location.replace(`/design/${curFashion.id}`)
        return true
    }

    //加载更多
    const onLoadMore = async (done: () => void) => {
        let cpPage = cloneDeep(page)
        cpPage++
        setPage(cpPage)
        try {
            await loadProductList(curProductGroupId, cpPage)
        } catch (error) {
            let cpPage = cloneDeep(page)
            cpPage--
            setPage(cpPage)
        }
        done()
    }

    //选择款式
    const choiceFashion = (v: any) => {
        setCurFashion(v)
    }


    return (
        <Animate show={show} style={{
            // display: show ? 'block' : 'none',
            bottom: 0,
            position: "fixed",
            left: 0,
            zIndex: 11,
            width: "100%",
            height: document.body.clientHeight,
            // paddingBottom: tc.safeHeigth
        }}>
            <div style={{ background: "rgba(0,0,0,0.7)", height: document.body.clientHeight / 2 + ActionTitleHeight, width: "100%", overflow: "hidden", visibility: (curFashion?.imgs && curFashion?.imgs?.length !== 0) ? "unset" : "hidden" }}>
                <div style={{ height: 25 }}></div>
                <Swiper loop={false} slidesPerView={1.5} effect="coverflow" centeredSlides={true} spaceBetween={30}>
                    {
                        curFashion?.imgs?.map((v: any) => (
                            <SwiperSlide key={v.url}>
                                <div style={{
                                    background: `url(${process.env.REACT_APP_OSS_URL + v.url + "?x-oss-process=image/resize,w_300"})`,
                                    backgroundSize: "100%",
                                    backgroundPosition: "center center",
                                    // width: "33%",
                                    height: document.body.clientHeight / 2 - ActionTitleHeight - 24,
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: 5
                                }}></div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className='fashionTab' style={{ background: "#FFFFFF", height: document.body.clientHeight / 2 - ActionTitleHeight, borderRadius: "12px 12px 0px 0px" }}>
                <Tabs titleGutter="10" titleScroll value={curProductGroupId} onChange={(e) => {
                    loadProduct(e.paneKey)
                }} background="#FFFFFF">
                    {
                        productGroups.map((v: any) => (
                            <Tabs.TabPane key={v.id} paneKey={v.id} title={v.name}></Tabs.TabPane>
                        ))
                    }
                </Tabs>
                <div style={{ display: patternList?.total === 0 ? "block" : "none", padding: "0 12px", height: document.body.clientHeight / 2 - 80, overflow: "auto" }}>
                    <Empty imageSize={Number(document.body.clientHeight / 2 - 50) * 0.5 || 0} description="无数据" />
                </div>

                <div style={{ display: patternList?.total === 0 ? "none" : "block", padding: "0 12px", height: document.body.clientHeight / 2 - 80, overflow: "auto" }} id="fashionScroll">
                    <Infiniteloading
                        loadIcon=""
                        containerId="fashionScroll"
                        useWindow={false}
                        hasMore={patternList?.total !== patternList?.list?.length}
                        onLoadMore={onLoadMore}
                        style={{
                            paddingBottom: 12
                        }}
                    >
                        {
                            patternList?.list?.map((v: any, dx: number) => (
                                <div key={v.id} onClick={() => choiceFashion(v)} style={{
                                    borderRadius: 5,
                                    background: "#F8F8F8",
                                    overflow: "hidden",
                                    padding: "0 12px",
                                    marginBottom: dx === patternList?.list?.length - 1 ? 0 : 12,
                                    border: curFashion.id === v.id ? "2px solid #FF6E5F" : "2px solid #F8F8F8"
                                }}>
                                    <div style={{ height: 10 }}></div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <div style={{
                                                width: 80,
                                                height: 80,
                                                background: `url(${process.env.REACT_APP_OSS_URL + v.cover + "?x-oss-process=image/resize,w_300"})`,
                                                backgroundPosition: "center center",
                                                backgroundSize: "cover",
                                                borderRadius: 5
                                            }}></div>
                                        </div>
                                        <div style={{ marginLeft: 12 }}>
                                            <div style={{
                                                fontWeight: 500,
                                                fontSize: 14
                                            }}>{v.name}</div>
                                            <span style={{
                                                color: "#F57766",
                                                fontSize: 16
                                            }}>¥ {(v.price / 100)?.toFixed(2)?.split(".")[0]}<span style={{
                                                color: "#F57766",
                                                fontSize: 14
                                            }}>.{(v.price / 100)?.toFixed(2)?.split(".")[1]}</span></span>
                                            <div style={{ marginTop: 12 }}>
                                                {
                                                    v?.tags && v?.tags?.map((j: any, ji: number) => (
                                                        <span key={ji} style={{
                                                            display: "inline-block",
                                                            background: "#000",
                                                            color: "#fff",
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            padding: "2px 12px",
                                                            borderRadius: 5,
                                                            marginRight: 7
                                                        }}>{j}</span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        height: 1,
                                        background: "#ccc",
                                        margin: "auto",
                                        marginTop: 10
                                    }}></div>
                                    <div style={{ display: "flex", height: 40, alignItems: "center" }}>
                                        <div style={{
                                            fontSize: 14,
                                            color: "#333",
                                            width: 80,
                                            textAlign: "center"
                                        }}>参考颜色</div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {
                                                v?.materials?.map_info?.map((i: any, n: number) => (
                                                    <div key={n} style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: 12
                                                    }}>
                                                        <span style={{
                                                            width: 12,
                                                            height: 12,
                                                            background: i.color,
                                                            display: "inline-block",
                                                            marginRight: 2,
                                                            borderRadius: 2
                                                        }}></span>
                                                        <span style={{
                                                            fontSize: 12
                                                        }}>{i.name}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Infiniteloading>
                </div>
            </div>
        </Animate>

    )

}

export default Index