import { useState, useImperativeHandle, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import TContext from '../../context'
import PContext from '../context'
import PaintIcon from '../../../../static/img/paint.png'
import EraserIcon from '../../../../static/img/eraser.png'
import Brush1Icon from '../../../../static/img/brush1.png'
import Brush2Icon from '../../../../static/img/brush2.png'
import Brush3Icon from '../../../../static/img/brush3.png'
import Animate from '../../../animate'

interface Props {
    action?: any
}

export interface Action {
    setShow?: (isShow: boolean) => void;
    cancel?: () => void
    confirm?: () => void
}

const contentHeight = 60
export const Height = contentHeight
var tempJson: any = null
const brushSize = [5, 10, 15, 20]
const brushColor = ["#FFFFFF", "#000000", "#4D4D4D", "#808080", "#B3B3B3", "#E6E6E6", "#3b2722", "#5a3f34", "#9f877d", "#d4cbc4", "#556c78", "#90a0ad", "#ced6da", "#f7e6b0", "#f7f4c3", "#f1f5e6", "#e3f0fb", "#eae6f3", "#f4dae2", "#fad3cb", "#205d62", "#3a9284", "#8cba53", "#4d6d45", "#b7d332", "#a5cea3", "#84c4ba", "#a3bbe1", "#9ca1d0", "#4b9ed8", "#1d5192", "#1e296e", "#3f4fa0", "#432082", "#584096", "#853b92", "#831e4c", "#e60012", "#e24c3d", "#f188ac", "#f69796", "#f38435", "#e4ad35", "#edd481", "#f6f19d", "#f0cb59", "#be8a2f", "#2c2929"]
const brushs = [
    {
        key: "setPencilBrush",
        img: Brush1Icon
    },
    {
        key: "setCircleBrush",
        img: Brush2Icon
    },
    {
        key: "setSprayBrush",
        img: Brush3Icon
    }
]

const Index = (props: Props) => {
    const [show, setInnerShow] = useState(false)
    const [currentBrushSize, setCurrentBrushSize] = useState<number>(brushSize[0])
    const [currentBrush, setCurrentBrush] = useState<string>(brushs[0].key)
    const [currentBrushColor, setCurrentBrushColor] = useState<string>(brushColor[1])
    const [isUseErase, setIsUseErase] = useState<boolean>(false)
    const [showChoiceBrush, setShowChoiceBrush] = useState<boolean>(false)
    const [showChoiceColor, setShowChoiceColor] = useState<boolean>(false)
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    useImperativeHandle(props.action, () => ({
        setShow,
        cancel,
        confirm
    }));

    const setShow = (isShow: boolean) => {
        if (isShow) {
            tempJson = tc?.fabricClient?.getJsonData()
            //设置默认笔刷
            tc.curCanvasClient[brushs[0].key](brushSize[0], brushColor[1])
            setCurrentBrush(brushs[0].key)
            setCurrentBrushSize(brushSize[0])
            //设置默认笔刷颜色
            setCurrentBrushColor(brushColor[1])
            //初始化橡皮选中状态
            setIsUseErase(false)
        }
        setInnerShow(isShow)
    }



    //取消
    const cancel = () => {
        //取消笔刷模式
        tc.curCanvasClient.cancelBrush()
        //关闭弹窗
        setInnerShow(false)
    }

    //确认
    const confirm = (e: any) => {
        pc?.addStep!(tempJson)
        //取消笔刷模式
        tc.curCanvasClient.cancelBrush()
        //关闭弹窗
        setInnerShow(false)
    }

    //打开笔刷盒子
    const openBrushBox = (e: any) => {
        e.preventDefault()
        setShowChoiceBrush(true)
    }

    //选择画笔
    const choiceBrush = (item: any) => {
        setCurrentBrush(item.key)
        tc.curCanvasClient[item.key](currentBrushSize, currentBrushColor)
        setShowChoiceBrush(false)
        setIsUseErase(false)
    }

    //选择笔刷大小
    const choiceBrushSize = (v: number) => {
        setCurrentBrushSize(v)
        if (isUseErase) {
            tc.curCanvasClient.setErase(v)
        } else {
            tc.curCanvasClient[currentBrush](v, currentBrushColor)
        }
    }

    //选择橡皮
    const choiceErase = (e: any) => {
        e.preventDefault()
        if (isUseErase) {
            tc.curCanvasClient[currentBrush](currentBrushSize, currentBrushColor)
            setIsUseErase(false)
        } else {
            tc.curCanvasClient.setErase(currentBrushSize)
            setIsUseErase(true)
        }
    }

    //打开颜色盒子
    const openBrushColorBox = (e: any) => {
        e.preventDefault()
        setShowChoiceColor(true)
    }

    //选择颜色
    const choiceBrushColor = (color: string) => {
        setCurrentBrushColor(color)
        setShowChoiceColor(false)
        tc.curCanvasClient[currentBrush](currentBrushSize, color)
    }


    return (
        <Animate show={show} style={{
            bottom: 0,
            position: "fixed",
            left: 0,
            zIndex: 11,
            width: "100%",
            background: "#FFFFFF",
            paddingBottom: tc.safeHeigth
        }}>

            <div style={{
                height: contentHeight,
                display: showChoiceBrush ? "flex" : "none",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}>
                {
                    brushs?.map((v: any) => (
                        <div key={v.key} onClick={(e) => {
                            e.preventDefault()
                            choiceBrush(v)
                        }} style={{
                            width: 109,
                            height: 50,
                            overflow: "hidden",
                            borderRadius: 5,
                            border: currentBrush === v.key ? "solid 1px #f57766" : "solid 1px #f5f5f5"
                        }}>
                            <img style={{
                                width: "100%",
                                height: "100%"
                            }} src={v.img} alt="" />
                        </div>
                    ))
                }
            </div>

            <div style={{
                height: contentHeight,
                display: showChoiceColor ? "flex" : "none",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}>
                <Swiper
                    slidesPerView={"auto"}
                    style={{
                        width: "96%"
                    }}
                >
                    {
                        brushColor?.map((v: any, k: number) => (
                            <SwiperSlide onClick={(e) => {
                                e.preventDefault()
                                choiceBrushColor(v)
                            }} style={{
                                width: 'auto'
                            }} key={v}>
                                <div style={{
                                    width: 30,
                                    height: 30,
                                    marginRight: k === brushColor.length - 1 ? 0 : 12,
                                    borderRadius: "50%",
                                    background: v,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: `1px solid ${brushColor[brushColor.length - k - 1]}`
                                }}>
                                    <div style={{
                                        width: "60%",
                                        height: "60%",
                                        borderRadius: "50%",
                                        border: currentBrushColor === v ? `1px solid ${brushColor[brushColor.length - k - 1]}` : "none"
                                    }}></div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>

            <div style={{
                height: contentHeight,
                display: (showChoiceBrush || showChoiceColor) ? "none" : "flex",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}>
                <div onClick={openBrushBox} style={{
                    textAlign: "center"
                }}>
                    <div style={{
                        width: 33,
                        height: 33,
                        background: "#ededed",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <img style={{
                            width: "38%"
                        }} src={PaintIcon} alt="" />
                    </div>
                    <div style={{ height: 6 }}></div>
                    <div style={{
                        fontSize: 12
                    }}>画笔</div>
                </div>

                <div onClick={choiceErase} style={{
                    textAlign: "center"
                }}>
                    <div style={{
                        width: 33,
                        height: 33,
                        background: isUseErase ? "#000000" : "#ededed",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <img style={{
                            width: "38%"
                        }} src={EraserIcon} alt="" />
                    </div>
                    <div style={{ height: 6 }}></div>
                    <div style={{
                        fontSize: 12
                    }}>橡皮</div>
                </div>

                <div style={{
                    textAlign: "center"
                }}>
                    <div style={{
                        width: 142,
                        height: 33,
                        background: "#ededed",
                        borderRadius: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly"
                    }}>
                        {
                            brushSize.map((v: number) => (
                                <div key={v} onClick={(e: any) => {
                                    e.preventDefault()
                                    choiceBrushSize(v)
                                }} style={{
                                    width: v,
                                    height: v,
                                    borderRadius: "50%",
                                    background: currentBrushSize === v ? "#000000" : "#afafaf"
                                }}></div>
                            ))
                        }
                    </div>
                    <div style={{ height: 6 }}></div>
                    <div style={{
                        fontSize: 12
                    }}>笔画大小</div>
                </div>

                <div onClick={openBrushColorBox} style={{
                    textAlign: "center"
                }}>
                    <div style={{
                        width: 33,
                        height: 33,
                        background: currentBrushColor,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${brushColor[brushColor.length - brushColor.findIndex(v => v === currentBrushColor) - 1]}`
                    }}>
                    </div>
                    <div style={{ height: 6 }}></div>
                    <div style={{
                        fontSize: 12
                    }}>颜色</div>
                </div>

            </div>
        </Animate>
    )

}

export default Index